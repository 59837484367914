import {useState, MouseEvent, useContext} from 'react'
import {TabHeader} from './tab-header'
import {ContentPageType} from '../../contexts/types/content-page'
import PageActionType from '../../contexts/state/page/action-type'
import {CreateOTAssetReq} from '../../../../store/state/ot-assets/state'
import {LocationInventoryPageContext} from '../../contexts/location-inventory-page-context'
import {useDispatch} from 'react-redux'
import {createAsset} from '../../contexts/state/asset-form/action-creators'
import {SystemsLevelTab} from '../systems-level-tab'
import {AssetsLevelTab} from '../assets-level-tab'

export function TabSection(): JSX.Element {
    const reduxDispatch = useDispatch()
    const {assetForm, page} = useContext(LocationInventoryPageContext)
    const [activeTab, setActiveTab] = useState<ContentPageType>(ContentPageType.SYSTEM_LEVEL)

    const toggle = (event: MouseEvent, tab: ContentPageType) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
        event.preventDefault()
    }

    const handleFormSubmit = (req: CreateOTAssetReq) => {
        createAsset(page.state.locationID!, assetForm.dispatch, reduxDispatch, req)
    }

    const handleFormDismiss = () => {
        page.dispatch?.({
            type: PageActionType.TOGGLE_SHOW_ASSET_CREATION_FORM,
            payload: {showForm: false},
        })
    }

    return (
        <>
            <TabHeader activeTab={activeTab} toggle={toggle} />
            <GetContentPageType
                selectedContentPage={activeTab}
                handleFormSubmit={handleFormSubmit}
                handleFormDismiss={handleFormDismiss}
            />
        </>
    )
}
interface GetContentPageTypeProps {
    selectedContentPage: ContentPageType
    handleFormSubmit: (req: CreateOTAssetReq) => void
    handleFormDismiss: () => void
}
function GetContentPageType({
    selectedContentPage,
    handleFormSubmit,
    handleFormDismiss,
}: GetContentPageTypeProps): JSX.Element {
    switch (selectedContentPage) {
        case ContentPageType.ASSET_LEVEL:
            return (
                <AssetsLevelTab
                    handleFormSubmit={handleFormSubmit}
                    handleFormDismiss={handleFormDismiss}
                />
            )
        case ContentPageType.SYSTEM_LEVEL:
            return <SystemsLevelTab />
        default:
            throw new Error('not implemented content page')
    }
}
