import Plot from 'react-plotly.js'
import styled from 'styled-components'
import {VesselPerformanceWithMetrics} from './types/metric-report-types'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../store/state/locations/selectors'

const PlotContainer = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
`

interface Props {
    vesselPerformance: VesselPerformanceWithMetrics
}

export function VesselHeatMapChart(props: Props) {
    const usersLocation = useTypedSelector(locationsSelector)

    if (!props.vesselPerformance.topN) {
        return null
    }

    // Take topN and bottomN, and combine them into 1 array
    let allVessels = props.vesselPerformance.topN.map((v) => v)
    // Concat only if not null
    allVessels = props.vesselPerformance.bottomN
        ? allVessels.concat(props.vesselPerformance.bottomN)
        : allVessels

    const allVesselsWithName = allVessels.map((v) => {
        const location = usersLocation.find((l) => l.location === v.locationId)
        return {
            ...v,
            locationName: location?.description ?? '',
        }
    })

    // Construct y axis
    const y = allVesselsWithName.map((v) =>
        v.locationName.length > 9 ? v.locationName.slice(0, 8) + '...' : v.locationName,
    )

    const metrics = [
        'Access control',
        'Security tools',
        'Removables control',
        'Malware Detection',
        'Asset Online',
        'OS Security',
        'Malware Definition',
        'USB Storage',
        'Wireless Connection',
        'Hotspot Mode',
        'Portable Software',
        'Mobile Devices',
    ]

    // Construct x axis data, which is the metrics.
    // and the order is according to `metrics` variable
    const data = allVesselsWithName.map((v) => [
        v.accessControls.totalAssetsWithIssue,
        v.securityTools.totalAssetsWithIssue,
        v.removableControls.totalAssetsWithIssue,
        v.malwareDetection.totalAssetsWithIssue,
        v.assetOnline.totalAssetsWithIssue,
        v.osSecurityPatches.totalAssetsWithIssue,
        v.malwareDefinition.totalAssetsWithIssue,
        v.usbStorage.totalAssetsWithIssue,
        v.wirelessConnection.totalAssetsWithIssue,
        v.hotspotMode.totalAssetsWithIssue,
        v.portableSoftware.totalAssetsWithIssue,
        v.mobileDevices.totalAssetsWithIssue,
    ])

    // Send in full location name to be displayed in tooltip
    const customData = data.map((row, i) => row.map(() => `${allVesselsWithName[i].locationName}`))
    return (
        <PlotContainer>
            <Plot
                data={[
                    {
                        z: data,
                        y: y,
                        customdata: customData,
                        x: metrics,
                        xgap: 1,
                        ygap: 1,
                        type: 'heatmap',
                        hovertemplate:
                            '<b>Vessel</b>: %{customdata}<br>' +
                            '<b>Metric</b>: %{x}<br>' +
                            '<b>Issue Count</b>: %{z}<br>' +
                            '<extra></extra>',
                        colorbar: {
                            title: 'Asset Count with Issue',
                            titleside: 'right',
                        },
                        colorscale: [
                            [0, '#0D0887'],
                            [0.0525, '#2C0594'],
                            [0.105, '#43039E'],
                            [0.1575, '#5901A5'],
                            [0.21, '#6E00A8'],
                            [0.2625, '#8305A7'],
                            [0.315, '#9511A1'],
                            [0.3675, '#A72197'],
                            [0.42, '#B6308B'],
                            [0.4725, '#C5407E'],
                            [0.525, '#D14E72'],
                            [0.5775, '#DD5E66'],
                            [0.63, '#E76E5B'],
                            [0.6825, '#F07F4F'],
                            [0.735, '#F79044'],
                            [0.7875, '#FCA338'],
                            [0.84, '#FEB72D'],
                            [0.8925, '#FCCD25'],
                            [0.945, '#F7E225'],
                            [1, '#F0F921'],
                        ],
                    },
                ]}
                layout={{
                    yaxis: {
                        // Most OK at top
                        autorange: 'reversed',
                    },
                    dragmode: false,
                    autosize: true,
                    margin: {
                        t: 25,
                    },
                }}
                config={{
                    scrollZoom: false,
                    editable: false,
                    displayModeBar: false,
                    showAxisDragHandles: false,
                    responsive: false,
                }}
            />
        </PlotContainer>
    )
}
