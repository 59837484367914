import {useState, useEffect, useRef, Fragment} from 'react'
import * as Style from './dropdown.styled'
import Chevron from '../../../@assets/icons/chevron.svg'
import CircleXIcon from '../../../@assets/icons/circle-x-blue.svg'

export type DropdownOption = {
    label: string
    value: string
    groupName?: string
}

type DropdownProps = {
    options: DropdownOption[]
    selectedOption: DropdownOption
    onChage: (option: DropdownOption) => void
    placeholder: string
    required?: boolean
    // Group options by its groupName
    groupOptions?: boolean
}

export function Dropdown(props: DropdownProps): JSX.Element {
    const [showOptions, setShowOptions] = useState(false)
    const [searchFilter, setSearchFilter] = useState('')

    const boxElem = useRef<HTMLDivElement>(null)
    const selectedElem = useRef<HTMLAnchorElement>(null)

    useEffect(() => {
        const close = (event: MouseEvent) => {
            if (!boxElem.current) {
                return
            }

            if (!boxElem.current.contains(event.target as Node)) {
                setShowOptions(false)
            }
        }

        window.addEventListener('click', close, true)

        return () => {
            window.removeEventListener('click', close)
        }
    }, [])

    useEffect(() => {
        if (!selectedElem.current || showOptions) {
            return
        }

        if (props.selectedOption.label === '') {
            return
        }

        selectedElem.current.focus()
    }, [props.selectedOption.label, showOptions])

    // Escape regex special characters and create a regex for search filter
    const filterRegex = new RegExp(searchFilter.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i')

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFilter(e.target.value)
    }

    const handleOptionClick = (event: React.MouseEvent) => {
        event.preventDefault()
        setShowOptions(!showOptions)
    }

    let renderedOptions: JSX.Element[] = []
    if (props.groupOptions) {
        const groupedOptions: Record<string, DropdownOption[]> = {}
        // Group the options by their groupName
        for (const opt of props.options) {
            if (opt.groupName !== undefined) {
                if (groupedOptions[opt.groupName] === undefined) {
                    groupedOptions[opt.groupName] = []
                }
                groupedOptions[opt.groupName].push(opt)
            }
        }

        // List of already rendered header of group name
        const renderedGroupName: Record<string, boolean> = {}

        for (const groupName of Object.keys(groupedOptions)) {
            // List of options in the group, filterred by the search
            const optInGroup = groupedOptions[groupName]
                .filter((option) => filterRegex.test(option.label))
                .map((option) => {
                    const optionElem = (
                        <Style.Option
                            key={option.value}
                            onClick={(event) => {
                                event.preventDefault()
                                setShowOptions(false)
                                props.onChage(option)
                            }}
                            href="#"
                        >
                            {option.label}
                        </Style.Option>
                    )
                    // Render the header only if its not rendered yet
                    if (groupName !== '' && !renderedGroupName[groupName]) {
                        // Mark the group name as rendered
                        renderedGroupName[groupName] = true
                        return (
                            <Fragment key={groupName}>
                                <Style.GroupName>{groupName}</Style.GroupName>
                                {optionElem}
                            </Fragment>
                        )
                    } else {
                        return optionElem
                    }
                })

            // Put options with no group at the beginning
            if (groupName === '') {
                renderedOptions.unshift(...optInGroup)
            } else {
                renderedOptions.push(...optInGroup)
            }
        }
    } else {
        // Render with no group
        renderedOptions = props.options
            .filter((option) => filterRegex.test(option.label))
            .map((option) => {
                return (
                    <Style.Option
                        key={option.value}
                        onClick={(event) => {
                            event.preventDefault()
                            setShowOptions(false)
                            props.onChage(option)
                        }}
                        href="#"
                    >
                        {option.label}
                    </Style.Option>
                )
            })
    }

    if (renderedOptions.length === 0) {
        renderedOptions = [<Style.Option key="no-option">No options</Style.Option>]
    }

    const selectedOption = props.selectedOption.label ? (
        <Style.Label>{props.selectedOption.label}</Style.Label>
    ) : (
        <Style.Placeholder>{props.placeholder}</Style.Placeholder>
    )

    const showRemoveBtn = props.selectedOption?.value && !props.required

    return (
        <Style.Box ref={boxElem}>
            <Style.Panel ref={selectedElem} onClick={handleOptionClick} href="#">
                <div
                    style={{
                        flex: 'auto',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {selectedOption}
                </div>
                {showRemoveBtn && (
                    <Style.RemoveBtn
                        src={CircleXIcon}
                        alt="cross to close"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            props.onChage({label: '', value: ''})
                        }}
                    />
                )}
                <img src={Chevron} alt="arrow down" />
            </Style.Panel>
            {showOptions && (
                <Style.DropdownContainer>
                    <Style.SearchInput
                        placeholder="Search"
                        value={searchFilter}
                        onChange={handleSearchChange}
                    />
                    <Style.OptionContainer>{renderedOptions}</Style.OptionContainer>
                </Style.DropdownContainer>
            )}
        </Style.Box>
    )
}
