import {REST} from '../../../..'
import {useContext} from 'react'
import {LocationInventoryPageContext} from '../location-inventory-page-context'
import type {OTSystems} from '../location-inventory-page-context'
import {
    requestOTSystems,
    setOTSystems,
    failFetchOTSystems,
    setShowGenerateReportPopup,
    setReportCreate,
    reportCreateError,
    resetErrorState,
} from '../state/ot-systems/action-creators'
import {GuidType} from '../../../../values/generic-type-defintions'

const OT_INVENTORY_BASE_ENDPOINT = `/ot-inventory/api/v1/`

interface UseOTSystemsContext {
    otSystems: OTSystems
    fetchOTSystems: (locationID: GuidType) => void
    createReport: () => void
    reportUrl: string | null
    reportDialogShown: boolean
    showGenerateReportPopup: (show: boolean) => void
    createReportError: string
    resetCreateReportError: () => void
}

export function useOTSystemsContext(): UseOTSystemsContext {
    const {
        otSystems,
        page: {
            state: {locationID},
        },
    } = useContext(LocationInventoryPageContext)
    const {dispatch, state} = otSystems
    const {createReportError} = state

    if (otSystems === undefined) {
        throw new Error('useOTSystemsContext must be used within an otSystemsContext')
    }

    if (!dispatch) {
        throw new Error('dispatch cannot be undefined')
    }
    const d = dispatch

    function fetchOTSystems(locationID: GuidType): void {
        const SYSTEMS_ENDPOINT = `${OT_INVENTORY_BASE_ENDPOINT}/locations/${locationID}/systems`
        dispatch!(requestOTSystems())
        REST.get(`${SYSTEMS_ENDPOINT}`, {retry: 3, retryDelay: 1000})
            .then((res) => {
                d(setOTSystems(res.data))
            })
            .catch(() => {
                d(failFetchOTSystems())
            })
    }

    function showGenerateReportPopup(show: boolean): void {
        d(setShowGenerateReportPopup(show))
    }

    function createReport(): void {
        d(resetErrorState())
        REST.post(
            `${OT_INVENTORY_BASE_ENDPOINT}/assessments/vesselSystems/report?locationID=${locationID}`,
        )
            .then((response) => {
                const {identity} = response.data
                const reportUrl = `/reports?reportDetails=${identity}`
                d(setReportCreate(reportUrl))
                d(setShowGenerateReportPopup(true))
            })
            .catch((error: Error) => {
                d(reportCreateError('An error occurred while creating OT Assessment Report.'))
                // eslint-disable-next-line no-console
                console.error(error)
            })
    }

    function resetCreateReportError(): void {
        d(resetErrorState())
    }

    return {
        otSystems,
        fetchOTSystems,
        reportUrl: state.reportUrl,
        reportDialogShown: state.showGenerateReportPopup,
        showGenerateReportPopup,
        createReport,
        createReportError,
        resetCreateReportError,
    }
}
